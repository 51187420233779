import { Accessor, Setter, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import SectionHeader from '@molecules/SectionHeader';
import JoinSessionBox from '@molecules/JoinSessionBox';

export interface JoinSectionProps {
    userName: Accessor<string>;
    setUserName: Setter<string>;
    onJoinExistingRoom: () => void | unknown;
}

const JoinSection: Component<JoinSectionProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <SectionHeader
                tagline={t('join.tagline')}
                title={t('join.header')}
            />
            <div class="h-6" />
            <JoinSessionBox
                userName={props.userName}
                setUserName={props.setUserName}
                onJoinExistingRoom={props.onJoinExistingRoom}
            />
        </>
    );
};

export default JoinSection;
