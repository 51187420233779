import {
    Accessor,
    Setter,
    createEffect,
    createSignal,
    onMount,
    type Component,
} from 'solid-js';
import {
    TranslationState,
    useTranslationState,
} from '@i18n/TranslationProvider';
import SectionHeader from '@molecules/SectionHeader';
import Button from '@atoms/Button';
import mailIcon from '@assets/icons/mail.svg';
import lockIcon from '@assets/icons/lock.svg';
import TextField from '@atoms/TextField';
import Checkbox from '@atoms/Checkbox';

export interface RegisterSectionProps {
    isLoading: boolean;
    email: Accessor<string>;
    setEmail: Setter<string>;
    password: Accessor<string>;
    setPassword: Setter<string>;
    onRegister: () => void | unknown;
}

const RegisterSection: Component<RegisterSectionProps> = (props) => {
    const translationState = useTranslationState();

    const [repeatPassword, setRepeatPassword] = createSignal('');
    let repeatPasswordInputRef: HTMLInputElement | undefined;
    createEffect(() => {
        if (props.password() === repeatPassword()) {
            repeatPasswordInputRef?.setCustomValidity('');
        } else {
            repeatPasswordInputRef?.setCustomValidity(
                translationState.t('register.passwordsDoNotMatch'),
            );
        }
    });

    const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
        createSignal(false);
    let acceptTermsRef: HTMLInputElement | undefined;
    createEffect(() => {
        if (acceptTermsAndConditions()) {
            acceptTermsRef?.setCustomValidity('');
        } else {
            acceptTermsRef?.setCustomValidity(
                translationState.t('register.termsNotAccepted'),
            );
        }
    });

    return (
        <>
            <SectionHeader
                tagline={translationState.t('register.tagline')}
                title={translationState.t('register.header')}
            />
            <div class="h-6" />
            <form
                onSubmit={(e) => {
                    props.onRegister();
                    e.preventDefault();
                }}
            >
                <TextField
                    value={props.email()}
                    onInput={(newValue) => props.setEmail(newValue)}
                    title={translationState.t('register.emailTitle')}
                    placeholderText={translationState.t(
                        'register.emailPlaceholder',
                    )}
                    icon={mailIcon}
                    type="email"
                    required={true}
                />
                <div class="h-3" />
                <TextField
                    value={props.password()}
                    onInput={props.setPassword}
                    title={translationState.t('register.passwordTitle')}
                    placeholderText={translationState.t(
                        'register.passwordPlaceholder',
                    )}
                    icon={lockIcon}
                    type="password"
                    required={true}
                />
                <div class="h-3" />
                <TextField
                    ref={repeatPasswordInputRef}
                    value={repeatPassword()}
                    onInput={setRepeatPassword}
                    title={translationState.t('register.repeatPasswordTitle')}
                    placeholderText={translationState.t(
                        'register.repeatPasswordPlaceholder',
                    )}
                    icon={lockIcon}
                    type="password"
                    required={true}
                />
                <div class="h-6" />
                <Checkbox
                    ref={acceptTermsRef}
                    isChecked={acceptTermsAndConditions}
                    setIsChecked={setAcceptTermsAndConditions}
                >
                    {translationState.t('register.acceptTermsText')}
                </Checkbox>
                <div class="h-6" />
                <Button
                    title={translationState.t('login.loginButton')}
                    fullWidth={true}
                    variant="primary"
                    type="submit"
                    isDisabled={props.isLoading}
                />
            </form>
        </>
    );
};

export default RegisterSection;
