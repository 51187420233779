import TextField from '@atoms/TextField';
import { Accessor, Setter, type Component, type JSX } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import Button from '@atoms/Button';

import personIcon from '@assets/icons/person.svg';

export interface JoinSessionBoxProps {
    userName: Accessor<string>;
    setUserName: Setter<string>;
    onJoinExistingRoom: () => void | unknown;
}

const JoinSessionBox: Component<JoinSessionBoxProps> = (
    props,
) => {
    const t = useTranslationState().t;
    return (
        <>
        <form
            onSubmit={(e) => {
                props.onJoinExistingRoom();
                e.preventDefault();
            }}>
            <TextField
                value={props.userName()}
                onInput={(newValue) => props.setUserName(newValue)}
                title={t('join.name.label')}
                placeholderText={t('join.name.placeholder')}
                icon={personIcon}
                type="text"
            />
            <div class="h-6" />
            <Button
                title={t('join.joinButton')}
                fullWidth={true}
                variant="primary"
                type='submit'
            />
            </form>
        </>
    );
};

export default JoinSessionBox;
