import { Accessor, Setter, createMemo, createSignal } from 'solid-js';

class AuthService {
    isLoggedIn: Accessor<boolean>;
    authToken: Accessor<string | null>;
    setAuthToken: Setter<string | null>;

    constructor() {
        const [authToken, setAuthToken] = createSignal<string | null>(null);
        this.authToken = authToken;
        this.setAuthToken = setAuthToken;
        this.isLoggedIn = createMemo(() => this.authToken() !== null);
    }

    public async Login(email: string, password: string): Promise<boolean> {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        try {
            if (email === password) {
                this.setAuthToken('good');
                return true;
            }
        } catch {
            /* empty */
        }
        this.setAuthToken(null);
        return false;
    }

    public async Register(email: string, password: string): Promise<boolean> {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        try {
            this.setAuthToken('good');
            return true;
        } catch {
            /* empty */
        }
        this.setAuthToken(null);
        return false;
    }
}

export default AuthService;
