import PlayersManager from './PlayersManager';
import { SetStoreFunction, createStore, reconcile } from 'solid-js/store';
import Room from '@model/api/response/Room';
import SignalRApi from '@root/api/signalRApi';
import Api from '@root/api/api';
import { serverVoteToPlayerVote } from '@model/PlayerVote';
import RouteBuilderService from './RouteBuilderService';
import { makePersisted } from '@solid-primitives/storage';

class SessionService {
    public room: Room;
    private setRoom: SetStoreFunction<Room>;

    private routeBuilderService: RouteBuilderService;
    private playersManager: PlayersManager;
    private signalRApi: SignalRApi;
    private api: Api;

    constructor(
        playersManager: PlayersManager,
        signalRApi: SignalRApi,
        routeBuilderService: RouteBuilderService,
        api: Api,
    ) {
        const [room, setRoom] = makePersisted(
            createStore<Room>({
                roomId: '',
                playerId: '',
                state: 'Lobby',
                playerNickname: '',
                players: [],
                ticketDescription: null,
                averageVote: null,
                voteDuration: null,
                roomNumber: 0,
            }),
            { storage: localStorage },
        );

        this.room = room;
        this.setRoom = setRoom;

        this.routeBuilderService = routeBuilderService;
        this.playersManager = playersManager;
        this.signalRApi = signalRApi;
        this.api = api;
        this.signalRApi.onRoomUpdated = this.onRoomUpdatedFromServer.bind(this);

        if (room.roomId !== '' && room.playerId !== '') {
            this.signalRApi.connect(room.roomId, room.playerId);
            this.applyApiResponse(
                room,
                room.players[0]?.nickname === room.playerNickname,
            );
        }
    }

    public async joinSession(userName: string, roomId: string): Promise<void> {
        const room = await this.api.joinRoom(userName, roomId);
        await this.initSession(room, false);
    }

    public async startSession(userName: string): Promise<void> {
        const room = await this.api.createRoom(userName);
        await this.initSession(room, true);
    }

    public getJoinSessionLink() {
        const baseUrl = window.location.origin;
        return (
            baseUrl +
            this.routeBuilderService.buildJoinLink(this.room.roomId ?? '')
        );
    }

    public async endSession(): Promise<void> {
        await this.signalRApi.disconnect();
    }

    public applyApiResponse(room: Room, isModerator: boolean | undefined) {
        this.setRoom(reconcile(room));
        const selfPlayer = this.playersManager.selfPlayer();
        if (!selfPlayer || selfPlayer.Name != room.playerNickname) {
            this.playersManager.setSelfName(
                room.playerNickname,
                isModerator ?? selfPlayer?.IsModerator ?? false,
            );
        }
        room.players.forEach((roomPlayer) => {
            const localPlayer = this.playersManager.players.find(
                (x) => x.Name === roomPlayer.nickname,
            );
            if (!localPlayer) {
                this.playersManager.createPlayer(roomPlayer, false);
            } else {
                this.playersManager.modifyPlayer(localPlayer.Id, (player) => {
                    player.Name = roomPlayer.nickname;
                    player.Vote = serverVoteToPlayerVote(roomPlayer.vote);
                    player.Status = roomPlayer.hasVote ? 'Ready' : 'Waiting';
                });
            }
        });
    }

    private async initSession(room: Room, isModerator: boolean): Promise<void> {
        this.playersManager.resetPlayers();

        this.applyApiResponse(room, isModerator);
        await this.signalRApi.connect(room.roomId, room.playerId);
    }

    private onRoomUpdatedFromServer(room: Room) {
        this.applyApiResponse(room, undefined);
    }
}

export default SessionService;
