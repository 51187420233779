import { Component, createSignal } from 'solid-js';
import { JSX } from 'solid-js/jsx-runtime';
import { useTranslationState } from '@i18n/TranslationProvider';
import Text from '@atoms/Text';

import MainImage from '@assets/main-image.jpeg';
import PageHeader from '@organisms/PageHeader';
import { useDi } from '@di/DependencyInjectionProvider';
import { A, useNavigate } from '@solidjs/router';
import Routes from '@root/Routes';

export interface LayoutProps {
    children?: JSX.Element;
}

const Layout: Component<LayoutProps> = (props) => {
    const [userName, setUserName] = createSignal('John');
    const t = useTranslationState().t;
    const di = useDi();
    const authService = di.authService;
    const navigation = useNavigate();
    return (
        <div class="flex flex-col h-full">
            <div class="flex-none self-stretch">
                <PageHeader
                    userName={userName}
                    isLoggedIn={authService.isLoggedIn}
                    onAboutClicked={() => {}}
                    onCreateRoomClicked={() => {
                        navigation(Routes.Index);
                    }}
                    onJobsClicked={() => {}}
                    onJoinRoomClicked={() => {
                        // TODO: Join room without room ID makes no sense
                    }}
                    onLoginClicked={() => {
                        navigation(Routes.Login);
                    }}
                    onProfileClicked={() => {}}
                />
            </div>
            <div class="flex flex-row flex-1 items-stretch">
                <div class="bg-neutral basis-3/5 hidden sm:flex flex-col">
                    <img
                        src={MainImage}
                        class="object-cover max-h-[100vh] max-w-[100vh] w-full h-full"
                    />
                    <div class="m-10">
                        <A href="https://devcity.tech">
                            <Text>{t('poweredBy')}</Text>
                        </A>
                    </div>
                </div>
                <div class="bg-neutral-light flex-1 flex items-center flex-col p-10 !min-w-96">
                    <div class="max-w-96 w-96 self-center h-full">
                        {props.children}
                    </div>
                    <div class="self-start flex sm:!hidden">
                        <A href="https://devcity.tech">
                            <Text>{t('poweredBy')}</Text>
                        </A>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
