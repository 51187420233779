import Player from '@model/Player';
import { Accessor, createMemo, createUniqueId } from 'solid-js';
import { SetStoreFunction, createStore, produce } from 'solid-js/store';
import AvatarProvider from './AvatarProvider';
import RoomPlayer from '@model/api/response/RoomPlayer';

class PlayersManager {
    public players: Player[];
    private setPlayersStore: SetStoreFunction<Player[]>;
    private selfPlayerId: string | null;
    private avatarProvider: AvatarProvider;
    public selfPlayer: Accessor<Player | undefined>;

    constructor(avatarProvider: AvatarProvider) {
        const [players, setPlayersStore] = createStore<Player[]>([]);
        this.players = players;
        this.setPlayersStore = setPlayersStore;
        this.selfPlayerId = null;
        this.avatarProvider = avatarProvider;
        this.selfPlayer = createMemo(() => this.getSelfPlayer());
    }

    public resetPlayers() {
        this.setPlayersStore([]);
        this.selfPlayerId = null;
    }

    public resetPlayersForNewVote() {
        this.players.forEach((player) => {
            this.modifyPlayer(player.Id, (player) => {
                player.Vote = null;
                player.Status = 'Waiting';
            });
        });
    }

    public resetPlayersReady() {
        this.players.forEach((player) => {
            this.modifyPlayer(player.Id, (player) => {
                player.Status = 'Waiting';
            });
        });
    }

    public modifySelf(modify: (state: Player) => void) {
        this.modifyPlayer(this.selfPlayerId, modify);
    }

    public modifyPlayer(
        playerId: string | null,
        modify: (state: Player) => void,
    ) {
        const index = this.getIndexOfPlayer(playerId);
        if (index < 0 || index >= this.players.length) {
            return;
        }
        this.setPlayersStore(index, produce(modify));
    }

    public createPlayer(player: RoomPlayer, isModerator: boolean): void {
        const newPlayer: Player = {
            Id: createUniqueId(),
            Name: player.nickname,
            Icon: this.avatarProvider.getRandomAvatar(),
            IsModerator: isModerator,
            IsSelf: false,
            Status: player.hasVote ? 'Ready' : 'Waiting',
        };
        this.setPlayersStore(this.players.length, newPlayer);
    }

    public setSelfName(name: string, isModerator: boolean): void {
        if (this.selfPlayerId === null) {
            const newPlayer: Player = {
                Id: createUniqueId(),
                Name: name,
                Icon: this.avatarProvider.getRandomAvatar(),
                IsModerator: isModerator,
                IsSelf: true,
                Status: 'Ready',
            };
            this.selfPlayerId = newPlayer.Id;
            this.setPlayersStore(this.players.length, newPlayer);
        } else {
            this.modifySelf((player) => {
                player.Name = name;
            });
        }
    }

    private getSelfPlayer(): Player | undefined {
        return this.players.find((x) => x.Id == this.selfPlayerId);
    }

    private getIndexOfPlayer(playerId: string | null): number {
        if (!playerId) {
            return -1;
        }
        return this.players.map((x) => x.Id).indexOf(playerId);
    }
}

export default PlayersManager;
