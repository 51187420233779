import * as i18n from '@solid-primitives/i18n';
import * as en from './languages/en';
import * as cs from './languages/cs';
import {
    Accessor,
    ParentComponent,
    Setter,
    createContext,
    createMemo,
    createSignal,
    useContext,
} from 'solid-js';

export type Locale = 'en' | 'cs';

type RawDictionary = typeof en.dict;
export type Dictionary = i18n.Flatten<RawDictionary>;

export const dictionaries = {
    en: en.dict,
    cs: cs.dict,
};

export interface TranslationState {
    locale: Accessor<Locale>;
    setLocale: Setter<Locale>;
    t: i18n.Translator<Dictionary>;
}

const TranslationContext = createContext<TranslationState>(
    {} as TranslationState,
);

export const useTranslationState = () => useContext(TranslationContext);

export const TranslationContextProvider: ParentComponent = (props) => {
    const [locale, setLocale] = createSignal<Locale>('en');

    const dict = createMemo(() => i18n.flatten(dictionaries[locale()]));

    const t = i18n.translator(dict, i18n.resolveTemplate);

    const state: TranslationState = {
        locale,
        setLocale,
        t,
    };

    return (
        <TranslationContext.Provider value={state}>
            {props.children}
        </TranslationContext.Provider>
    );
};
