import { type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import TableHeader from '@molecules/TableHeader';
import TableRow from '@molecules/TableRow';

export interface ResultsTableProps {
    votingTime: string;
    averageVote: number;
}

export type TableType = 'Voting' | 'Lobby';

const ResultsTable: Component<ResultsTableProps> = (props) => {
    const translationState = useTranslationState();
    const t = translationState.t;
    return (
        <>
            <TableHeader text={t('resultsTable.header')} />
            <TableRow
                text={t('resultsTable.votingTime')}
                isHighlighted={false}
                isLast={false}
                rightView={props.votingTime}
            />
            <TableRow
                text={t('resultsTable.averageVote')}
                isHighlighted={false}
                isLast={true}
                rightView={props.averageVote}
            />
        </>
    );
};

export default ResultsTable;
