import { RoomState } from '@model/api/response/RoomState';
import Routes from '@root/Routes';

class RouteBuilderService {
    public buildRoomRoute(roomId: string, roomState: RoomState) {
        switch (roomState) {
            case 'Lobby':
                return this.replaceArg(Routes.Lobby, 'roomId', roomId);
            case 'Voting':
                return this.replaceArg(Routes.Voting, 'roomId', roomId);
            case 'Result':
                return this.replaceArg(Routes.VotingResult, 'roomId', roomId);
            case 'End':
                return this.replaceArg(Routes.VotingEnd, 'roomId', roomId);
        }
    }

    public buildJoinLink(roomId: string) {
        return this.replaceArg(Routes.Join, 'roomId', roomId);
    }

    public buildHistoryLink(roomId: string) {
        return this.replaceArg(Routes.VotingHistory, 'roomId', roomId);
    }

    private replaceArg(route: string, name: string, value: string) {
        return route.replace(':' + name, value);
    }
}

export default RouteBuilderService;
