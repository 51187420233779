export const dict = {
    lobby: {
        tagline: 'Session {{ sessionNumber }} 🫵',
        headerModerator: 'Invite players to the room',
        headerUser: 'Waiting for the moderator to start the session',
        sendLinkInstructions:
            'Send the link below to your team mates and soon they will start joining the room',
        nameSessionTickets: 'Name session tickets',
        startEstimating: 'Start estimating',
        ticketDescriptionPlaceholder: 'New ticket description',
    },
    homepage: {
        tagline: 'Scrum pointing poker ️♦️♣️♥️♠️',
        header: 'Create your planning room.',
        name: {
            label: 'Enter your name to start',
            placeholder: 'Darth Vader',
        },
        createSessionButton: 'Create session',
        joinSessionButton: 'Or join existing room',
        createAccount: {
            title: 'Create free account',
            description: 'Save history of pointing poker sessions.',
            loginRegister: 'Login / Register',
        },
    },
    join: {
        tagline: 'Scrum pointing poker ️♦️♣️♥️♠️',
        header: 'Join the planning poker room:',
        name: {
            label: 'Please enter your name to start',
            placeholder: 'Darth Vader',
        },
        joinButton: 'Join room',
    },
    login: {
        tagline: 'Choose your destiny 👋',
        title: 'Login with email',
        email: {
            label: 'Email',
            placeholder: 'Your login email',
        },
        password: {
            label: 'Password',
            placeholder: 'Your password',
        },
        loginButton: 'Continue with email',
        separator: 'or Log in with',
        disclaimer:
            'By continuing you agree Taskez’s Terms of Services & Privacy Policy ',
        registerButton: 'Register new account',
        forgotPasswordButton: 'I forgot my password',
    },
    header: {
        jobs: 'Jobs',
        about: 'About',
        createRoom: 'Create room',
        joinRoom: 'Join room',
        loginRegister: 'Login / Register',
    },
    playersBox: {
        header: 'Players in room 🕹️',
        moderatorSubtitle: 'Moderator',
        ready: 'Ready',
        waiting: 'Waiting',
        offline: 'Offline',
        you: 'You',
    },
    resultsTable: {
        header: 'Results 🕹️',
        votingTime: 'Voting time',
        averageVote: 'Average vote',
    },
    poweredBy: 'Powered by DEVCITY 🔥',
    voting: {
        header: 'Session #{{ sessionNumber }}',
        nowVotingWithName: 'Now voting: {{ ticketName }}',
        nowVotingWithoutName: 'Now voting',
        showVotingHistory: 'Show voting history',
        endSession: 'End session',
        voteButtonsLabel: 'Vote your choice',
        revealButton: 'Reveal estimation',
        readyButton: {
            notReady: 'Set ready for next vote',
            ready: 'Ready for next vote',
        },
        waitingForModerator:
            'Waiting for the moderator to continue session or repeat voting',
        voteAgain: 'Vote again',
        startNewEstimation: 'Start new estimation',
    },
    votingHistory: {
        tagline: 'Session #{{ sessionNumber }} history 👇',
        ticketDescriptionPlaceholder: 'No name ticket',
    },
    sessionEndModal: {
        header: 'Set session name to end',
        nameFieldTitle: 'Session name',
        nameFieldPlaceholder: 'Set name for your session to end it',
        description:
            'By ending session you will have your history saved for 7 days. By creating free account you can explore more features like full history of all sessions.',
        endSessionButton: 'End Session',
    },
    sessionEndPage: {
        sessionWasEnded: 'Session #{{ sessionNumber }} was ended',
        createNewSession: 'Create new session',
    },
    generic: {
        cancel: 'Cancel',
    },
    register: {
        tagline: 'Welcome aboard 👋',
        header: 'New account',
        emailTitle: 'Email',
        emailPlaceholder: 'Your login email',
        passwordTitle: 'Password',
        passwordPlaceholder: 'Your password',
        repeatPasswordTitle: 'Repeat password',
        repeatPasswordPlaceholder: 'Your password',
        acceptTermsText: 'I accept the terms and conditions',
        createAccount: 'Create account',
        passwordsDoNotMatch: 'Passwords do not match',
        termsNotAccepted: 'You need to accept the terms and conditions',
    },
};
