import { createEffect, createSignal, type Component } from 'solid-js';
import { useDi } from '@di/DependencyInjectionProvider';
import VotingResultSection from '@organisms/VotingResultSection';
import { useNavigate } from '@solidjs/router';
import Routes from '@root/Routes';
import AsyncOperation from '@root/AsyncOperation';
import LoadingOverlay from '@atoms/LoadingOverlay';

const VotingResultPage: Component = () => {
    const di = useDi();
    const playersManager = di.playersManager;
    const isModerator = playersManager.selfPlayer()?.IsModerator ?? false;
    const navigate = useNavigate();

    const [nameSessionTickets, setNameSessionTickets] = createSignal<boolean>(
        di.sessionService.room.ticketDescription !== null,
    );
    const [ticketDescription, setTicketDescription] = createSignal('');

    const startVoteOperation = new AsyncOperation<
        { ticketDescription: string | null },
        void
    >();

    const restartVoteOperation = new AsyncOperation<{}, void>();

    const endVoteOperation = new AsyncOperation<
        { sessionName: string },
        void
    >();

    createEffect(() => {
        if (di.sessionService.room.state !== 'Result') {
            navigate(
                di.routeBuilderService.buildRoomRoute(
                    di.sessionService.room.roomId ?? '',
                    di.sessionService.room.state,
                ),
            );
        }
    });

    return (
        <LoadingOverlay
            isLoading={
                startVoteOperation.resource.loading ||
                restartVoteOperation.resource.loading ||
                endVoteOperation.resource.loading
            }
        >
            <VotingResultSection
                isModerator={isModerator}
                pokerAddress={di.sessionService.getJoinSessionLink()}
                sessionNumber={di.sessionService.room.roomNumber ?? 0}
                players={playersManager.players}
                ticketName={di.sessionService.room.ticketDescription}
                nameSessionTickets={nameSessionTickets}
                setNameSessionTickets={setNameSessionTickets}
                ticketDescription={ticketDescription}
                setTicketDescription={setTicketDescription}
                isReady={() => playersManager.selfPlayer()?.Status === 'Ready'}
                setIsReady={(isReady: boolean) =>
                    playersManager.modifySelf((player) => {
                        player.Status = isReady ? 'Ready' : 'Waiting';
                    })
                }
                averageVote={di.sessionService.room.averageVote ?? 0}
                votingTime={formatVoteDuration(
                    di.sessionService.room.voteDuration,
                )}
                onVoteAgain={() => {
                    restartVoteOperation.start({}, async (_) => {
                        const roomId = di.sessionService.room.roomId ?? '';
                        const playerId = di.sessionService.room.playerId ?? '';
                        await di.api.restartVote(roomId, playerId);
                    });
                }}
                onStartNewEstimation={() => {
                    startVoteOperation.start(
                        {
                            ticketDescription: nameSessionTickets()
                                ? ticketDescription()
                                : null,
                        },
                        async (args) => {
                            const roomId = di.sessionService.room.roomId ?? '';
                            const playerId =
                                di.sessionService.room.playerId ?? '';
                            await di.api.startNewVote(
                                roomId,
                                playerId,
                                args.ticketDescription,
                            );
                        },
                    );
                }}
                onEndSession={(sessionName) => {
                    endVoteOperation.start(
                        { sessionName: sessionName },
                        async (args) => {
                            const roomId = di.sessionService.room.roomId ?? '';
                            const playerId =
                                di.sessionService.room.playerId ?? '';
                            await di.api.endRoom(
                                roomId,
                                playerId,
                                args.sessionName,
                            );
                        },
                    );
                }}
                onShowVotingHistory={() => {
                    navigate(
                        di.routeBuilderService.buildHistoryLink(
                            di.sessionService.room.roomId,
                        ),
                    );
                }}
            />
        </LoadingOverlay>
    );
};

function formatVoteDuration(s: number | null): string {
    if (s === null) {
        return '';
    }

    const seconds = s;
    const secondsString = (seconds % 60).toFixed(0) + ' Sec';
    const minutes = s / 60;
    const minutesString = (minutes % 60).toFixed(0) + ' Min';
    const hours = s / (60 * 60);
    const hoursString = hours.toFixed(0) + ' H';
    if (seconds < 60) {
        return secondsString;
    } else if (minutes < 60) {
        return minutesString + ', ' + secondsString;
    } else {
        return hoursString + ', ' + minutesString + ', ' + secondsString;
    }
}

export default VotingResultPage;
