import { Component, JSX } from 'solid-js';

export interface StatusIndicatorProps {
    text: string;
    color: StatusIndicatorColor;
}

export type StatusIndicatorColor = 'green' | 'red' | 'grey';

const StatusIndicator: Component<StatusIndicatorProps> = (props) => {
    return (
        <span class="flex items-center">
            <div
                classList={{
                    'from-gradient-green-start to-gradient-green-end':
                        props.color === 'green',
                    'from-gradient-red-start to-gradient-red-end':
                        props.color === 'red',
                    '!bg-secondary-dark': props.color === 'grey',
                }}
                class="size-3 bg-gradient-to-b rounded-full inline-block "
            />
            <p
                classList={{
                    'from-gradient-green-start to-gradient-green-end':
                        props.color === 'green',
                    'from-gradient-red-start to-gradient-red-end':
                        props.color === 'red',
                    '!bg-secondary-dark': props.color === 'grey',
                }}
                class="bg-gradient-to-b inline-block text-transparent bg-clip-text font-medium ml-2 text-xs"
            >
                {props.text}
            </p>
        </span>
    );
};

export default StatusIndicator;
