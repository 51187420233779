import { createResource, type Component } from 'solid-js';
import { useDi } from '@di/DependencyInjectionProvider';
import { useNavigate } from '@solidjs/router';
import VotingHistorySection from '@organisms/VotingHistorySection';
import LoadingOverlay from '@atoms/LoadingOverlay';

const VotingHistoryPage: Component = () => {
    const di = useDi();
    const navigate = useNavigate();

    const [data, { mutate, refetch }] = createResource(async () => {
        return await di.api.getHistory(
            di.sessionService.room.roomId,
            di.sessionService.room.playerId,
        );
    });
    return (
        <LoadingOverlay isLoading={data.loading}>
            <VotingHistorySection
                sessionNumber={di.sessionService.room.roomNumber ?? 0}
                results={data()}
                onBack={() => navigate(-1)}
            />
        </LoadingOverlay>
    );
};

export default VotingHistoryPage;
