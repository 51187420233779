import LobbySection from '@organisms/LobbySection';
import { createEffect, createSignal, type Component } from 'solid-js';
import { useDi } from '@di/DependencyInjectionProvider';
import { useNavigate } from '@solidjs/router';
import AsyncOperation from '@root/AsyncOperation';
import LoadingOverlay from '@atoms/LoadingOverlay';

const LobbyPage: Component = () => {
    const di = useDi();
    const playersManager = di.playersManager;
    const isModerator = playersManager.selfPlayer()?.IsModerator ?? false;
    const navigate = useNavigate();

    const [nameSessionTickets, setNameSessionTickets] = createSignal(false);
    const [ticketDescription, setTicketDescription] = createSignal('');

    const asyncOperation = new AsyncOperation<
        { ticketDescription: string | null },
        void
    >();

    createEffect(() => {
        if (di.sessionService.room.state !== 'Lobby') {
            navigate(
                di.routeBuilderService.buildRoomRoute(
                    di.sessionService.room.roomId ?? '',
                    di.sessionService.room.state,
                ),
            );
        }
    });

    return (
        <LoadingOverlay isLoading={asyncOperation.resource.loading}>
            <LobbySection
                isModerator={isModerator}
                pokerAddress={di.sessionService.getJoinSessionLink()}
                sessionNumber={di.sessionService.room.roomNumber ?? 0}
                nameSessionTickets={nameSessionTickets}
                setNameSessionTickets={setNameSessionTickets}
                ticketDescription={ticketDescription}
                setTicketDescription={setTicketDescription}
                onStartEstimating={() => {
                    asyncOperation.start(
                        {
                            ticketDescription: nameSessionTickets()
                                ? ticketDescription()
                                : null,
                        },
                        async (args) => {
                            const roomId = di.sessionService.room.roomId ?? '';
                            const playerId =
                                di.sessionService.room.playerId ?? '';
                            await di.api.startNewVote(
                                roomId,
                                playerId,
                                args.ticketDescription,
                            );
                        },
                    );
                }}
                players={playersManager.players}
            />
        </LoadingOverlay>
    );
};

export default LobbyPage;
