import { Resource, Setter, createResource, createSignal } from 'solid-js';

class AsyncOperation<T, R> {
    public resource: Resource<R>;
    private setOperationArguments: (arg: T) => void;
    private callback: ((args: T) => Promise<R>) | null;

    constructor() {
        const [operationArguments, setOperationArguments] =
            createSignal<T | null>(null);

        const [operationResource] = createResource(
            operationArguments,
            async (args) => {
                if (!this.callback) {
                    throw new Error('Callback must be defined');
                }
                return await this.callback(args);
            },
        );

        this.resource = operationResource;
        this.setOperationArguments = setOperationArguments;
        this.callback = null;
    }

    public start(args: T, callback: (args: T) => Promise<R>): void {
        this.callback = callback;
        this.setOperationArguments(args);
    }
}

export default AsyncOperation;
