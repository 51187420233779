import Button from '@atoms/Button';
import Text from '@atoms/Text';
import TextField from '@atoms/TextField';
import { useTranslationState } from '@i18n/TranslationProvider';
import { createSignal, type Component } from 'solid-js';

export interface SessionEndModalProps {
    onCancel: () => void;
    onEndSessionPressed: (sessionName: string) => void;
}

const SessionEndModal: Component<SessionEndModalProps> = (props) => {
    const t = useTranslationState().t;
    const [sessionName, setSessionName] = createSignal('');
    return (
        <div class="p-6 bg-table-row rounded-xl">
            <p class="text-3xl font-semibold text-secondary-white pr-12 pb-4">
                {t('sessionEndModal.header')}
            </p>
            <TextField
                title={t('sessionEndModal.nameFieldTitle')}
                placeholderText={t('sessionEndModal.nameFieldPlaceholder')}
                value={sessionName()}
                onInput={(value) => setSessionName(value)}
            ></TextField>
            <div class="min-w-full max-w-0 text-base mt-2">
                <Text wrap={true} color="light" fontWeight="semibold">
                    {t('sessionEndModal.description')}
                </Text>
            </div>
            <div class="mt-4 grid-cols-2 grid-rows-1 grid gap-6">
                <Button variant="secondary" onClick={props.onCancel}>
                    {t('generic.cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => props.onEndSessionPressed(sessionName())}
                    isDisabled={sessionName() === ''}
                >
                    {t('sessionEndModal.endSessionButton')}
                </Button>
            </div>
        </div>
    );
};

export default SessionEndModal;
