import Header3 from '@atoms/Header3';
import { Component } from 'solid-js';

export interface TableHeaderProps {
    text: string;
}

const TableHeader: Component<TableHeaderProps> = (props) => {
    return (
        <div class=" bg-table-header rounded-t-xl p-4">
            <Header3>{props.text}</Header3>
        </div>
    );
};

export default TableHeader;
