import { For, JSXElement, type Component } from 'solid-js';
import {
    TranslationState,
    useTranslationState,
} from '@i18n/TranslationProvider';
import TableHeader from '@molecules/TableHeader';
import Player from '@model/Player';
import TableRow from '@molecules/TableRow';
import ready from '@assets/icons/ready.svg';
import waiting from '@assets/icons/waiting.svg';
import StatusIndicator, { StatusIndicatorColor } from '@atoms/StatusIndicator';
import { PlayerStatus } from '@model/PlayerStatus';

export interface PlayersTableProps {
    players: Player[];
    type: TableType;
    votesVisible: boolean;
}

export type TableType = 'Voting' | 'Lobby';

const PlayersTable: Component<PlayersTableProps> = (props) => {
    const translationState = useTranslationState();
    const t = translationState.t;
    return (
        <>
            <TableHeader text={t('playersBox.header')} />
            <For each={props.players}>
                {(player, i) => (
                    <TableRow
                        text={player.Name}
                        subtitle={
                            player.IsModerator
                                ? t('playersBox.moderatorSubtitle')
                                : null
                        }
                        icon={player.Icon}
                        isHighlighted={player.IsSelf}
                        showKebab={false}
                        isLast={i() === props.players.length - 1}
                        rightView={GetRightView(
                            player,
                            props.votesVisible,
                            props.type,
                            translationState,
                        )}
                    />
                )}
            </For>
        </>
    );
};

function GetRightView(
    player: Player,
    votesVisible: boolean,
    type: TableType,
    translationState: TranslationState,
): JSXElement {
    switch (type) {
        case 'Voting':
            if (votesVisible) {
                return player.Vote ?? 0;
            } else {
                return (
                    <img
                        class="inline-block"
                        src={player.Status == 'Ready' ? ready : waiting}
                    />
                );
            }
        case 'Lobby':
            return (
                <StatusIndicator
                    text={StatusToText(
                        player.Status,
                        player.IsSelf,
                        translationState,
                    )}
                    color={StatusToColor(player.Status)}
                />
            );
    }
}

function StatusToColor(status: PlayerStatus): StatusIndicatorColor {
    switch (status) {
        case 'Waiting':
            return 'red';
        case 'Ready':
            return 'green';
        case 'Offline':
            return 'grey';
    }
}

function StatusToText(
    status: PlayerStatus,
    isSelf: boolean,
    translationState: TranslationState,
): string {
    const t = translationState.t;
    if (isSelf) {
        return t('playersBox.you');
    }
    switch (status) {
        case 'Waiting':
            return t('playersBox.waiting');
        case 'Ready':
            return t('playersBox.ready');
        case 'Offline':
            return t('playersBox.offline');
    }
}

export default PlayersTable;
