import Header1 from '@atoms/Header1';
import Header3 from '@atoms/Header3';
import { Component } from 'solid-js';

export interface SectionHeaderProps {
    tagline: string;
    title: string;
}

const SectionHeader: Component<SectionHeaderProps> = (props) => {
    return (
        <>
            <Header3>{props.tagline}</Header3>
            <div class="h-6" />
            <Header1>{props.title}</Header1>
        </>
    );
};

export default SectionHeader;
