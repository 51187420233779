import { Accessor, For, Setter, type Component } from 'solid-js';
import Button from '@atoms/Button';
import { PlayerVote } from '@model/PlayerVote';

export interface VotingButtonsProps {
    selectedScore: Accessor<PlayerVote>;
    setSelectedScore: (vote: PlayerVote) => void;
}

const VotingButtons: Component<VotingButtonsProps> = (props) => {
    const possibleVotes: Array<PlayerVote> = [1, 2, 3, 5, 8, 13, 21, '?'];
    return (
        <div class="grid grid-cols-4 grid-rows-2 gap-2 h-52 w-full">
            <For each={possibleVotes}>
                {(vote) => (
                    <Button
                        variant={
                            vote === props.selectedScore()
                                ? 'primary-rounded'
                                : 'grey'
                        }
                        title={vote?.toString()}
                        onClick={() => props.setSelectedScore(vote)}
                        bigFont={true}
                    ></Button>
                )}
            </For>
        </div>
    );
};

export default VotingButtons;
