import { Accessor, Setter, Show, type Component } from 'solid-js';
import Checkbox from '@atoms/Checkbox';
import TextField from '@atoms/TextField';
import { useTranslationState } from '@i18n/TranslationProvider';

export interface TicketDescriptionBoxProps {
    nameSessionTickets: Accessor<boolean>;
    setNameSessionTickets: Setter<boolean>;
    ticketDescription: Accessor<string>;
    setTicketDescription: Setter<string>;
}

const TicketDescriptionBox: Component<TicketDescriptionBoxProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <Checkbox
                isChecked={props.nameSessionTickets}
                setIsChecked={props.setNameSessionTickets}
            >
                {t('lobby.nameSessionTickets')}
            </Checkbox>
            <Show when={props.nameSessionTickets()}>
                <div class="h-2" />
                <TextField
                    value={props.ticketDescription()}
                    onInput={props.setTicketDescription}
                    placeholderText={t('lobby.ticketDescriptionPlaceholder')}
                />
            </Show>
        </>
    );
};

export default TicketDescriptionBox;
