import { Accessor, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import VotingHistoryTable from '@organisms/VotingHistoryTable';
import Button from '@atoms/Button';
import back from '@assets/icons/back.svg';
import VoteResult from '@model/api/response/VoteResult';

export interface VotingHistorySectionProps {
    results: VoteResult[] | undefined;
    sessionNumber: number;
    onBack: () => void;
}

export type TableType = 'Voting' | 'Lobby';

const VotingHistorySection: Component<VotingHistorySectionProps> = (props) => {
    const translationState = useTranslationState();
    const t = translationState.t;
    return (
        <>
            <Button variant="tertiary-grey" onClick={props.onBack}>
                <img src={back} class=" inline-block mr-4" /> Back to game
            </Button>
            <div class="h-4" />
            <VotingHistoryTable
                sessionNumber={props.sessionNumber}
                results={props.results}
            ></VotingHistoryTable>
        </>
    );
};

export default VotingHistorySection;
