import TextField from '@atoms/TextField';
import { Accessor, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import { writeClipboard } from '@solid-primitives/clipboard';

import copyIcon from '@assets/icons/copy.svg';
import Text from '@atoms/Text';

export interface RoomLinkBoxProps {
    joinUrl: Accessor<string>;
}

const RoomLinkBox: Component<RoomLinkBoxProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <Text color="light" fontWeight="semibold">
                {t('lobby.sendLinkInstructions')}
            </Text>
            <div class="h-2" />
            <TextField
                value={props.joinUrl()}
                icon={copyIcon}
                type="text"
                readonly={true}
                onClick={() => {
                    writeClipboard(props.joinUrl());
                }}
            />
        </>
    );
};

export default RoomLinkBox;
