import Room from '@model/api/response/Room';
import VoteResult from '@model/api/response/VoteResult';

class Api {
    private baseUrl: string;
    constructor() {
        this.baseUrl = import.meta.env.VITE_BASE_URL || window.location.origin;
    }

    public async createRoom(nickname: string): Promise<Room> {
        return (await this.post('rooms', { nickname: nickname })) as Room;
    }

    public async joinRoom(nickname: string, roomId: string): Promise<Room> {
        return (await this.post('rooms/' + roomId, {
            nickname: nickname,
        })) as Room;
    }

    public async vote(
        roomId: string,
        playerId: string,
        vote: number,
    ): Promise<Room> {
        return (await this.post(
            'rooms/' +
                roomId +
                '/players/' +
                playerId +
                '/vote/' +
                vote.toString(),
            {},
        )) as Room;
    }

    public async openHand(roomId: string, playerId: string): Promise<Room> {
        return (await this.post(
            'rooms/' + roomId + '/players/' + playerId + '/openhand/',
            {},
        )) as Room;
    }

    public async startNewVote(
        roomId: string,
        playerId: string,
        ticketDescription: string | null,
    ): Promise<Room> {
        return (await this.post(
            'rooms/' + roomId + '/players/' + playerId + '/vote/start',
            { ticketDescription: ticketDescription },
        )) as Room;
    }

    public async restartVote(roomId: string, playerId: string): Promise<Room> {
        return (await this.post(
            'rooms/' + roomId + '/players/' + playerId + '/vote/restart',
            {},
        )) as Room;
    }

    public async endRoom(
        roomId: string,
        playerId: string,
        sessionName: string,
    ): Promise<Room> {
        return (await this.post(
            'rooms/' + roomId + '/players/' + playerId + '/end',
            { sessionName: sessionName },
        )) as Room;
    }

    public async getHistory(
        roomId: string,
        playerId: string,
    ): Promise<[VoteResult]> {
        return (await this.get(
            'rooms/' + roomId + '/players/' + playerId + '/history',
            {},
        )) as [VoteResult];
    }

    private async get(
        endpoint: string,
        queryParams: Record<string, string | null>,
    ): Promise<object | null> {
        return await this.request('GET', endpoint, queryParams);
    }

    private async post(
        endpoint: string,
        queryParams: Record<string, string | null>,
    ): Promise<object | null> {
        return await this.request('POST', endpoint, queryParams);
    }

    private async request(
        method: string,
        endpoint: string,
        queryParams: Record<string, string | null>,
    ): Promise<object | null> {
        const response = await fetch(
            this.baseUrl +
                '/api/' +
                endpoint +
                this.encodeQueryParams(queryParams),
            {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        if (response.status != 200) {
            throw new Error('Non success status code');
        }
        return await response.json();
    }

    private encodeQueryParams(
        queryParams: Record<string, string | null>,
    ): string {
        let toReturn = '?';
        let isFirst = true;
        for (const name in queryParams) {
            const value = queryParams[name];
            if (value === null) {
                continue;
            }

            if (!isFirst) {
                toReturn += '&';
            }
            toReturn += name + '=';
            toReturn += encodeURIComponent(value);
            isFirst = false;
        }
        return toReturn;
    }
}

export default Api;
