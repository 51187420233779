import { createScheduled, debounce } from '@solid-primitives/scheduled';
import { Component, createEffect, createSignal, JSX, Suspense } from 'solid-js';
import { Spinner, SpinnerType } from 'solid-spinner';

export interface LoadingOverlayProps {
    children: JSX.Element;
    isLoading: boolean;
}

const LoadingOverlay: Component<LoadingOverlayProps> = (props) => {
    const scheduled = createScheduled((fn) => debounce(fn, 500));
    const [showSpinner, setShowSpinner] = createSignal(false);

    createEffect(() => {
        const loading = props.isLoading;
        if (!loading || scheduled()) {
            setShowSpinner(loading);
        }
    });
    return (
        <Suspense>
            {props.children}
            <div
                classList={{
                    '!flex': showSpinner(),
                }}
                class="fixed hidden w-full h-full top-0 right-0 bottom-0 left-0 bg-neutral-dark bg-opacity-50 z-10 cursor-pointer items-center justify-center"
            >
                <Spinner type={SpinnerType.tailSpin} color="white" />
            </div>
        </Suspense>
    );
};

export default LoadingOverlay;
