import TextField from '@atoms/TextField';
import { Accessor, Setter, type Component, type JSX } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import Button from '@atoms/Button';

import personIcon from '@assets/icons/person.svg';

export interface CreateOrJoinSessionBoxProps {
    userName: Accessor<string>;
    setUserName: Setter<string>;
    onCreateSession: () => void | unknown;
    onJoinExistingRoom: () => void | unknown;
}

const CreateOrJoinSessionBox: Component<CreateOrJoinSessionBoxProps> = (
    props,
) => {
    const t = useTranslationState().t;
    return (
        <>
            <form
                onSubmit={(e) => {
                    props.onCreateSession();
                    e.preventDefault();
                }}
            >
                <TextField
                    value={props.userName()}
                    onInput={(newValue) => props.setUserName(newValue)}
                    title={t('homepage.name.label')}
                    placeholderText={t('homepage.name.placeholder')}
                    icon={personIcon}
                    type="text"
                />
                <div class="h-6" />
                <Button
                    onClick={() => props.onCreateSession()}
                    title={t('homepage.createSessionButton')}
                    fullWidth={true}
                    variant="primary"
                />
                {/* TODO: Enable when we figure out what the join button should do.
            <div class="h-6" />
            <Button
                title={t('homepage.joinSessionButton')}
                fullWidth={true}
                variant="tertiary"
                type='submit'
            /> */}
            </form>
        </>
    );
};

export default CreateOrJoinSessionBox;
