class Routes {
    public static readonly Index = '/';
    public static readonly Login = '/login';
    public static readonly Register = '/register';
    public static readonly Join = '/join/:roomId';
    public static readonly Lobby = '/lobby';
    public static readonly Voting = '/voting/:roomId/';
    public static readonly VotingResult = '/voting/:roomId/result';
    public static readonly VotingHistory = '/voting/:roomId/history';
    public static readonly VotingEnd = '/voting/:roomId/end';
}
export default Routes;
