import { useNavigate } from '@solidjs/router';
import { useDi } from '@di/DependencyInjectionProvider';
import {
    Suspense,
    createResource,
    createSignal,
    type Component,
} from 'solid-js';
import RegisterSection from '@organisms/RegisterSection';

const RegisterPage: Component = () => {
    const navigate = useNavigate();
    const [email, setEmail] = createSignal('');
    const [password, setPassword] = createSignal('');
    const di = useDi();
    const authService = di.authService;

    const [registerArguments, setRegisterArguments] = createSignal<
        [string, string] | null
    >(null);

    const [registerOperation] = createResource(
        registerArguments,
        async ([email, password]) => {
            const success = await authService.Register(email, password);
            if (success) {
                navigate('/');
            }
        },
    );
    return (
        <Suspense>
            <RegisterSection
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                onRegister={() => {
                    setRegisterArguments([email(), password()]);
                }}
                isLoading={registerOperation.loading}
            />
        </Suspense>
    );
};

export default RegisterPage;
