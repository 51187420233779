import { Accessor, Setter, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import CreateOrJoinSessionBox from '@molecules/CreateOrJoinSessionBox';
import infoIcon from '@assets/icons/info.svg';

export interface VotingEndSectionProps {
    userName: Accessor<string>;
    setUserName: Setter<string>;
    onCreateSession: () => void | unknown;
    onJoinExistingRoom: () => void | unknown;
    sessionNumber: string;
}

const VotingEndSection: Component<VotingEndSectionProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <div class="rounded-xl border-2 border-gr250 p-5 items-center justify-center flex flex-col mb-12">
                <img src={infoIcon} class="pb-4" />
                <p class="text-secondary-purple text-4xl font-bold text-wrap text-center">
                    {t('sessionEndPage.sessionWasEnded', {
                        sessionNumber: props.sessionNumber,
                    })}
                </p>
            </div>
            <p class="text-4xl text-secondary-white font-bold">
                {t('sessionEndPage.createNewSession')}
            </p>
            <div class="h-6" />
            <CreateOrJoinSessionBox
                userName={props.userName}
                setUserName={props.setUserName}
                onCreateSession={props.onCreateSession}
                onJoinExistingRoom={props.onJoinExistingRoom}
            />
        </>
    );
};

export default VotingEndSection;
