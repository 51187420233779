import { Component, JSX } from 'solid-js';

export interface Header3Props {
    children: JSX.Element;
}

const Header3: Component<Header3Props> = (props) => {
    return (
        <h3 class="text-secondary-purple font-SpaceGrotesk text-sm font-bold">
            {props.children}
        </h3>
    );
};

export default Header3;
