import { Accessor, Component, JSX, Setter } from 'solid-js';

import checkmark from '@assets/icons/checkmark.svg';

export interface CheckboxProps {
    children: JSX.Element;
    isChecked: Accessor<boolean>;
    setIsChecked: Setter<boolean>;
    ref?: HTMLInputElement | undefined;
}

const Checkbox: Component<CheckboxProps> = (props) => {
    return (
        <label class="cursor-pointer items-center flex text-secondary-light font-SpaceGrotesk font-bold hover:opacity-80">
            <input
                ref={props.ref}
                type="checkbox"
                checked={props.isChecked()}
                class="h-5 w-5 opacity-0 absolute"
                onInput={(e) => props.setIsChecked(e.target.checked)}
            />
            <span
                classList={{
                    'bg-positive': props.isChecked(),
                    'border-placeholder border-2': !props.isChecked(),
                }}
                class="h-5 w-5
                
                rounded grid mr-4"
            >
                <img
                    classList={{
                        invisible: !props.isChecked(),
                    }}
                    src={checkmark}
                    class="place-self-center visible"
                />
            </span>
            {props.children}
        </label>
    );
};

export default Checkbox;
