import HomeSection from '@organisms/HomeSection';
import { useNavigate } from '@solidjs/router';
import { createSignal, type Component } from 'solid-js';
import Routes from '@root/Routes';
import { useDi } from '@di/DependencyInjectionProvider';
import AsyncOperation from '@root/AsyncOperation';
import LoadingOverlay from '@atoms/LoadingOverlay';

const HomePage: Component = () => {
    const [userName, setUserName] = createSignal('');
    const navigation = useNavigate();
    const di = useDi();

    const createRoomOperation = new AsyncOperation<
        { userName: string },
        void
    >();

    return (
        <LoadingOverlay isLoading={createRoomOperation.resource.loading}>
            <HomeSection
                userName={userName}
                setUserName={setUserName}
                onCreateSession={() => {
                    if (userName() === 'sentrytest') {
                        throw new Error('Sentry Frontend Test Error');
                    }
                    createRoomOperation.start(
                        { userName: userName() },
                        async (args) => {
                            await di.sessionService.startSession(args.userName);
                            navigation(Routes.Lobby);
                        },
                    );
                }}
                onJoinExistingRoom={() => {
                    // TODO: Join room without room ID makes no sense
                }}
                onLoginOrRegister={() => {
                    navigation(Routes.Login);
                }}
            />
        </LoadingOverlay>
    );
};

export default HomePage;
