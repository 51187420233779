import Button from '@atoms/Button';
import { useTranslationState } from '@i18n/TranslationProvider';
import SessionEndModal from '@organisms/SessionEndModal';
import { type Component } from 'solid-js';
import { Modal, ModalRootElement } from 'solid-js-modal';

export interface VotingModeratorFooterProps {
    onShowHistoryPressed: () => void;
    onEndSessionPressed: (sessionName: string) => void;
}

const VotingModeratorFooter: Component<VotingModeratorFooterProps> = (
    props,
) => {
    const t = useTranslationState().t;
    // Hack to make typescript not complain.
    let modalRef = undefined as any as ModalRootElement;
    return (
        <div class="grid grid-cols-2 grid-rows-1">
            <div>
                <Button
                    variant="tertiary-grey"
                    noHorizontalPadding={true}
                    onClick={props.onShowHistoryPressed}
                >
                    {t('voting.showVotingHistory')}
                </Button>
            </div>
            <div class=" text-right">
                <Button
                    variant="tertiary-grey"
                    noHorizontalPadding={true}
                    onClick={() => modalRef.showModal()}
                >
                    {t('voting.endSession')}
                </Button>
            </div>
            <Modal
                ref={modalRef}
                shouldCloseOnBackgroundClick={true}
                class=" backdrop:bg-neutral backdrop:opacity-50 bg-transparent"
            >
                <SessionEndModal
                    onCancel={() => modalRef.close()}
                    onEndSessionPressed={props.onEndSessionPressed}
                />
            </Modal>
        </div>
    );
};

export default VotingModeratorFooter;
