import TextField from '@atoms/TextField';
import { Accessor, Setter, type Component, type JSX } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import mailIcon from '@assets/icons/mail.svg';
import lockIcon from '@assets/icons/lock.svg';
import Button from '@atoms/Button';

export interface EmailLoginBoxProps {
    email: Accessor<string>;
    setEmail: Setter<string>;
    password: Accessor<string>;
    setPassword: Setter<string>;
    onLoginClicked: () => void | unknown;
    isLoading: boolean;
}

const EmailLoginBox: Component<EmailLoginBoxProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <form
                onSubmit={(e) => {
                    props.onLoginClicked();
                    e.preventDefault();
                }}
            >
                <TextField
                    value={props.email()}
                    onInput={(newValue) => props.setEmail(newValue)}
                    title={t('login.email.label')}
                    placeholderText={t('login.email.placeholder')}
                    icon={mailIcon}
                    type="email"
                    required={true}
                />
                <div class="h-3" />
                <TextField
                    value={props.password()}
                    onInput={(newValue) => props.setPassword(newValue)}
                    title={t('login.password.label')}
                    placeholderText={t('login.password.placeholder')}
                    icon={lockIcon}
                    type="password"
                    required={true}
                />
                <div class="h-6" />
                <Button
                    title={t('login.loginButton')}
                    fullWidth={true}
                    variant="primary"
                    type="submit"
                    isDisabled={props.isLoading}
                />
            </form>
        </>
    );
};

export default EmailLoginBox;
