import { Accessor, Setter, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import SectionHeader from '@molecules/SectionHeader';
import CreateOrJoinSessionBox from '@molecules/CreateOrJoinSessionBox';
import CreateAccountBox from '@molecules/CreateFreeAccountBox';

export interface HomeSectionProps {
    userName: Accessor<string>;
    setUserName: Setter<string>;
    onCreateSession: () => void | unknown;
    onJoinExistingRoom: () => void | unknown;
    onLoginOrRegister: (event: MouseEvent) => void | unknown;
}

const HomeSection: Component<HomeSectionProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <SectionHeader
                tagline={t('homepage.tagline')}
                title={t('homepage.header')}
            />
            <div class="h-6" />
            <CreateOrJoinSessionBox
                userName={props.userName}
                setUserName={props.setUserName}
                onCreateSession={props.onCreateSession}
                onJoinExistingRoom={props.onJoinExistingRoom}
            />
            {/* TODO: Enable when doing login
            <div class="h-14" />
            <CreateAccountBox onLoginRegister={props.onLoginOrRegister} />  */}
        </>
    );
};

export default HomeSection;
