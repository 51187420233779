import { Accessor, For, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import TableHeader from '@molecules/TableHeader';
import VotingHistoryRow from '@molecules/VotingHistoryRow';
import VoteResult from '@model/api/response/VoteResult';

export interface VotingHistoryTableProps {
    results: VoteResult[] | undefined;
    sessionNumber: number;
}

export type TableType = 'Voting' | 'Lobby';

const VotingHistoryTable: Component<VotingHistoryTableProps> = (props) => {
    const translationState = useTranslationState();
    const t = translationState.t;
    return (
        <>
            <TableHeader
                text={t('votingHistory.tagline', {
                    sessionNumber: props.sessionNumber,
                })}
            />
            <For each={props.results}>
                {(result, i) => (
                    <VotingHistoryRow
                        votingIndex={result.index}
                        ticketDescription={result.ticketDescription}
                        duration={result.duration}
                        result={result.result}
                        isLast={i() === props.results!.length - 1}
                    />
                )}
            </For>
        </>
    );
};

export default VotingHistoryTable;
