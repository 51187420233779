import { type Component, type JSX } from 'solid-js';

export type ButtonVariants =
    | 'primary'
    | 'primary-rounded'
    | 'secondary'
    | 'tertiary'
    | 'tertiary-grey'
    | 'circular'
    | 'none'
    | 'default'
    | 'positive'
    | 'grey';

export type ButtonTypes = 'button' | 'submit' | 'reset';

export interface ButtonProps {
    onClick?: (event: MouseEvent) => void | unknown;
    variant: ButtonVariants;
    children?: JSX.Element;
    title?: string;
    isDisabled?: boolean;
    fullWidth?: boolean;
    type?: ButtonTypes;
    hidden?: boolean;
    fullHeight?: boolean;
    bigFont?: boolean;
    noHorizontalPadding?: boolean;
}

const Button: Component<ButtonProps> = (props) => {
    return (
        <button
            disabled={props.isDisabled}
            classList={{
                hidden: props.hidden ?? false,
                'w-full': props.fullWidth,
                'h-full': props.fullHeight,
                'rounded-lg border-2 border-secondary-dark bg-transparent':
                    props.variant === 'secondary',
                '!bg-transparent !text-primary !font-bold':
                    props.variant === 'tertiary',
                '!bg-transparent !text-secondary !font-bold':
                    props.variant === 'tertiary-grey',
                '!bg-transparent rounded-xl border-2 border-secondary-light focus-within:border-primary':
                    props.variant === 'default',
                '!bg-transparent !rounded-full border-2 border-secondary-light focus-within:border-primary w-14 h-14 flex items-center justify-center':
                    props.variant === 'circular',
                '!bg-transparent !leading-0 !flex !items-center !justify-center':
                    props.variant === 'none',
                '!bg-table-row rounded-xl border-2 border-table-highlighted !text-secondary-light':
                    props.variant === 'grey',
                'rounded-lg border-0 from-gradient-green-start to-gradient-green-end border-gradient-to-b bg-gradient-to-b flex items-center':
                    props.variant === 'positive',
                'text-4xl': props.bigFont,
                'rounded-xl': props.variant === 'primary-rounded',
                '!pl-0 !pr-0': props.noHorizontalPadding,
            }}
            class="bg-primary text-secondary-white rounded-xl font-bold leading-6 disabled:opacity-40 font-SpaceGrotesk"
            onClick={(e) => (props.onClick ? props.onClick(e) : () => {})}
            type={props.type || 'button'}
        >
            <div
                classList={{
                    'rounded-lg p-2 rounded-lf !m-[0.15rem] bg-neutral-light flex-1':
                        props.variant === 'positive',
                    '!ml-0 !mr-0': props.noHorizontalPadding,
                    'm-0':
                        props.variant === 'none' ||
                        props.variant === 'circular',
                }}
                class="m-3"
            >
                <div
                    classList={{
                        'bg-gradient-to-b inline-block text-transparent bg-clip-text from-gradient-green-start to-gradient-green-end':
                            props.variant === 'positive',
                    }}
                >
                    {props.title || props.children}
                </div>
            </div>
        </button>
    );
};

export default Button;
