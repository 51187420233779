import Text from '@atoms/Text';
import { useTranslationState } from '@i18n/TranslationProvider';
import { Component, Show } from 'solid-js';

export interface VotingHistoryRowProps {
    votingIndex: number;
    ticketDescription: string | null;
    duration: number;
    result: number;
    isLast: boolean;
}

const VotingHistoryRow: Component<VotingHistoryRowProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <div
            classList={{
                'rounded-b-xl': props.isLast,
                'mb-[1px]': !props.isLast,
            }}
            class="bg-table-row w-full p-2 flex-row flex"
        >
            <div class="flex-1 flex-row flex self-center">
                <div class="flex-none inline-block text-[0.6rem]">
                    <Text fontWeight="bold">
                        {formatVoteIndex(props.votingIndex)}
                    </Text>
                </div>
                <div class="pl-1 pr-1 text-xs">
                    <Text color="white" fontWeight="medium" wrap={true}>
                        {' '}
                        {props.ticketDescription ??
                            t(
                                'votingHistory.ticketDescriptionPlaceholder',
                            )}{' '}
                    </Text>
                </div>
            </div>
            <div class="flex-none inline-block text-[0.6rem] pl-1 pr-1 self-center">
                <Text fontWeight="bold">
                    {formatVoteDuration(props.duration)}
                </Text>
            </div>
            <div class="w-6 h-8 bg-primary rounded-[3px] font-bold text-secondary-white text-center content-center self-center">
                {props.result.toFixed()}
            </div>
        </div>
    );
};

function formatVoteIndex(index: number): string {
    let prefix = '#';
    if (index < 10) {
        prefix += '0';
    }
    if (index < 100) {
        prefix += '0';
    }
    return prefix + index.toString();
}

// TODO: Probably make some formatting service or something, similar to formatting elsewhere
function formatVoteDuration(s: number | null): string {
    if (s === null) {
        return '';
    }

    const seconds = s;
    const minutes = s / 60;
    const hours = s / (60 * 60);
    if (seconds < 60) {
        return (seconds % 60).toFixed(0) + ' sec.';
    } else if (minutes < 60) {
        return (minutes % 60).toFixed(0) + ' min.';
    } else {
        return hours.toFixed(0) + ' h.';
    }
}

export default VotingHistoryRow;
