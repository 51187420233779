import {
    HubConnection,
    HubConnectionBuilder,
    LogLevel,
} from '@microsoft/signalr';
import Room from '@model/api/response/Room';

class SignalRApi {
    private baseUrl: string;
    private connection: HubConnection | null = null;
    public onRoomUpdated: ((room: Room) => void) | null = null;
    constructor() {
        this.baseUrl = import.meta.env.VITE_BASE_URL || window.location.origin;
    }

    public async connect(roomId: string, playerId: string): Promise<void> {
        this.disconnect();

        this.connection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl(this.baseUrl + '/hubs/roomHub')
            .withAutomaticReconnect()
            .build();

        await this.connection.start();

        this.connection.on('RoomUpdated', (room: Room) => {
            if (this.onRoomUpdated) {
                this.onRoomUpdated(room);
            } else {
                console.warn('SignalR update received but noone was listening');
            }
        });

        await this.connection.send('ListenRoom', roomId, playerId);
    }

    public async disconnect(): Promise<void> {
        this.connection?.stop();
        this.connection = null;
    }
}

export default SignalRApi;
