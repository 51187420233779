import { Accessor, Setter, Show, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import SectionHeader from '@molecules/SectionHeader';
import Checkbox from '@atoms/Checkbox';
import TextField from '@atoms/TextField';
import Button from '@atoms/Button';
import RoomLinkBox from '@molecules/RoomLinkBox';
import Player from '@model/Player';
import PlayersTable from '@organisms/PlayersTable';
import TicketDescriptionBox from '@molecules/TicketDescriptionBox';

export interface LobbySectionProps {
    isModerator: boolean;
    pokerAddress: string;
    sessionNumber: number;
    nameSessionTickets: Accessor<boolean>;
    setNameSessionTickets: Setter<boolean>;
    ticketDescription: Accessor<string>;
    setTicketDescription: Setter<string>;
    onStartEstimating: () => void | unknown;
    players: Player[];
}

const LobbySection: Component<LobbySectionProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <SectionHeader
                tagline={t('lobby.tagline', {
                    sessionNumber: props.sessionNumber,
                })}
                title={
                    props.isModerator
                        ? t('lobby.headerModerator')
                        : t('lobby.headerUser')
                }
            />
            <div class="h-6" />
            <RoomLinkBox joinUrl={() => props.pokerAddress} />
            <div class="h-6" />
            <PlayersTable
                players={props.players}
                type="Lobby"
                votesVisible={false}
            />
            <div class="h-6" />
            <Show when={props.isModerator}>
                <TicketDescriptionBox
                    ticketDescription={props.ticketDescription}
                    setTicketDescription={props.setTicketDescription}
                    nameSessionTickets={props.nameSessionTickets}
                    setNameSessionTickets={props.setNameSessionTickets}
                />
                <div class="h-4" />
                <Button
                    variant="primary"
                    fullWidth={true}
                    onClick={props.onStartEstimating}
                >
                    {t('lobby.startEstimating')}
                </Button>
            </Show>
        </>
    );
};

export default LobbySection;
