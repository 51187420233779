import Header1 from '@atoms/Header1';
import Text from '@atoms/Text';
import { useTranslationState } from '@i18n/TranslationProvider';
import { type Component } from 'solid-js';

export interface VotingHeaderProps {
    sessionNumber: number;
    ticketName: string | null;
}

const VotingHeader: Component<VotingHeaderProps> = (props) => {
    const t = useTranslationState().t;

    return (
        <>
            <Header1>
                {t('voting.header', { sessionNumber: props.sessionNumber })}
            </Header1>
            <div class="h-2" />
            <Text color="light" fontWeight="semibold">
                {props.ticketName
                    ? t('voting.nowVotingWithName', {
                          ticketName: props.ticketName,
                      })
                    : t('voting.nowVotingWithoutName')}
            </Text>
        </>
    );
};

export default VotingHeader;
