import Text from '@atoms/Text';
import { Component, JSXElement, Show } from 'solid-js';

import kebab from '@assets/icons/kebab.svg';
import Button from '@atoms/Button';

export interface VotingResultRowProps {
    icon?: string;
    text: string;
    subtitle?: string | null;
    rightView?: JSXElement;
    showKebab?: boolean;
    onKebabPressed?: () => void;

    isLast: boolean;
    isHighlighted: boolean;
}

const VotingResultRow: Component<VotingResultRowProps> = (props) => {
    return (
        <div
            classList={{
                'rounded-b-xl': props.isLast,
                '!bg-table-highlighted': props.isHighlighted,
            }}
            class=" bg-table-row h-14 flex items-center pl-4 pr-4 w-full"
        >
            <Show when={props.icon}>
                <img src={props.icon} class="flex-none" />
            </Show>
            <div class="flex-1 flex-col pl-2 min-w-0 overflow-clip mr-2">
                <Text fontWeight="semibold" color="light" wrap={false}>
                    {props.text}
                </Text>
                <Show when={props.subtitle}>
                    <Text type="disclaimer" fontWeight="medium" wrap={false}>
                        {props.subtitle}
                    </Text>
                </Show>
            </div>
            <div class="flex-none text-secondary-light font-semibold w-20 text-right">
                {props.rightView}
            </div>
            <div class="flex-none w-10 h-full">
                <Show when={props.showKebab ?? false}>
                    <Button
                        variant="none"
                        fullHeight={true}
                        fullWidth={true}
                        onClick={props.onKebabPressed}
                    >
                        <img src={kebab} />
                    </Button>
                </Show>
            </div>
        </div>
    );
};

export default VotingResultRow;
