import { Component, JSX } from 'solid-js';

export interface TextProps {
    children: JSX.Element;
    type?: TextType;
    align?: TextAlignType;
    fontWeight?: FontWeightType;
    color?: FontColor;
    wrap?: boolean;
}

export type TextType = 'normal' | 'disclaimer';
export type TextAlignType = 'start' | 'end' | 'center' | 'justify';
export type FontWeightType = 'normal' | 'bold' | 'semibold' | 'medium';
export type FontColor = 'light' | 'dark' | 'white';

const Text: Component<TextProps> = (props) => {
    return (
        <p
            classList={{
                '!text-secondary !text-xs': props.type === 'disclaimer',
                '!text-start': props.align === 'start',
                '!text-center': props.align === 'center',
                '!text-end': props.align === 'end',
                '!text-justify': props.align === 'justify',
                '!font-normal': props.fontWeight === 'normal',
                '!font-medium': props.fontWeight === 'medium',
                '!font-bold': props.fontWeight === 'bold',
                '!font-semibold': props.fontWeight === 'semibold',
                '!text-secondary-dark': props.color === 'dark',
                '!text-secondary-light': props.color === 'light',
                '!text-secondary-white': props.color === 'white',
                'text-nowrap': !(props.wrap ?? true),
            }}
            class="text-secondary-dark font-SpaceGrotesk"
        >
            {props.children}
        </p>
    );
};

export default Text;
