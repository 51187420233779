import { Show, onMount, type Component } from 'solid-js';

export type TextFieldType =
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

export interface TextFieldProps {
    onInput?: (input: string) => void;
    onClick?: () => void;
    title?: string;
    value?: string;
    placeholderText?: string;
    icon?: string;
    type?: TextFieldType;
    required?: boolean;
    readonly?: boolean;
    ref?: HTMLInputElement | undefined;
}

const TextField: Component<TextFieldProps> = (props) => {
    return (
        <label class="text-secondary-light font-medium block text-xs">
            {props.title || ''}
            <div class="rounded-xl border-2 border-secondary-light flex p-4 mt-1 bg-neutral focus-within:border-primary">
                <input
                    classList={{
                        'cursor-pointer': props.readonly,
                    }}
                    ref={props.ref}
                    class="bg-transparent inline text-secondary-white flex-1 placeholder:text-placeholder focus-visible:outline-none pr-4"
                    placeholder={props.placeholderText ?? ''}
                    onInput={(e) =>
                        props.onInput
                            ? props.onInput(e.target.value || '')
                            : () => {}
                    }
                    onClick={() => (props.onClick ? props.onClick() : () => {})}
                    value={props.value || ''}
                    type={props.type || 'text'}
                    required={props.required ?? false}
                    readonly={props.readonly ?? false}
                />
                <Show when={props.icon}>
                    <img src={props.icon} class="inline flex-none" />
                </Show>
            </div>
        </label>
    );
};

export default TextField;
