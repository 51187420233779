import { Component, JSX } from 'solid-js';

export interface Header1Props {
    children: JSX.Element;
}

const Header1: Component<Header1Props> = (props) => {
    return (
        <h1 class="text-secondary-white font-SpaceGrotesk text-5xl font-semibold">
            {props.children}
        </h1>
    );
};

export default Header1;
