import { Accessor, Setter, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import SectionHeader from '@molecules/SectionHeader';
import EmailLoginBox from '@molecules/EmailLoginBox';
import Separator from '@atoms/Separator';
import Button from '@atoms/Button';
import Text from '@atoms/Text';

import MicrosoftIcon from '@assets/icons/third-parties/microsoft.svg';
import GoogleIcon from '@assets/icons/third-parties/google.svg';
import GithubIcon from '@assets/icons/third-parties/github.svg';

export interface LoginSectionProps {
    isLoading: boolean;
    email: Accessor<string>;
    setEmail: Setter<string>;
    password: Accessor<string>;
    setPassword: Setter<string>;
    onLoginClicked: () => void | unknown;
    onMicrosoftLoginClicked: () => void | unknown;
    onGoogleLoginClicked: () => void | unknown;
    onGithubLoginClicked: () => void | unknown;
    onRegisterClicked: () => void | unknown;
    onForgotPasswordClicked: () => void | unknown;
}

const LoginSection: Component<LoginSectionProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <SectionHeader
                tagline={t('login.tagline')}
                title={t('login.title')}
            />
            <div class="h-6" />
            <EmailLoginBox
                email={props.email}
                setEmail={props.setEmail}
                password={props.password}
                setPassword={props.setPassword}
                onLoginClicked={props.onLoginClicked}
                isLoading={props.isLoading}
            />
            <div class="h-6" />
            <Separator>{t('login.separator')}</Separator>
            <div class="h-6" />
            <span class="flex items-center flex-row justify-center space-x-3">
                <Button
                    variant="circular"
                    onClick={props.onMicrosoftLoginClicked}
                >
                    <img src={MicrosoftIcon} />
                </Button>
                <Button variant="circular" onClick={props.onGoogleLoginClicked}>
                    <img src={GoogleIcon} />
                </Button>
                <Button variant="circular" onClick={props.onGithubLoginClicked}>
                    <img src={GithubIcon} />
                </Button>
            </span>
            <div class="h-6" />
            <Text type="disclaimer" align="center">
                {t('login.disclaimer')}
            </Text>
            <div class="h-6" />
            <Button
                variant="default"
                fullWidth={true}
                onClick={props.onRegisterClicked}
            >
                {t('login.registerButton')}
            </Button>
            <div class="h-6" />
            <Button
                variant="tertiary-grey"
                fullWidth={true}
                onClick={props.onForgotPasswordClicked}
            >
                {t('login.forgotPasswordButton')}
            </Button>
        </>
    );
};

export default LoginSection;
