export type PlayerVote = number | null | '?';

export function playerVoteToServerVote(vote: PlayerVote): number {
    if (vote === null) {
        return 0;
    } else if (vote === '?') {
        return -1;
    } else {
        return vote;
    }
}

export function serverVoteToPlayerVote(serverVote: number): PlayerVote {
    if (serverVote > 0) {
        return serverVote;
    } else if (serverVote === -1) {
        return '?';
    } else {
        return null;
    }
}
