import { Accessor, Component, Show } from 'solid-js';

import Logo from '@assets/logo.svg';
import Button from '@atoms/Button';
import { useTranslationState } from '@i18n/TranslationProvider';

export interface PageHeaderProps {
    userName: Accessor<string>;
    isLoggedIn: Accessor<boolean>;
    onLoginClicked: (event: MouseEvent) => void | unknown;
    onProfileClicked: (event: MouseEvent) => void | unknown;
    onJobsClicked: (event: MouseEvent) => void | unknown;
    onAboutClicked: (event: MouseEvent) => void | unknown;
    onCreateRoomClicked: (event: MouseEvent) => void | unknown;
    onJoinRoomClicked: (event: MouseEvent) => void | unknown;
}

const PageHeader: Component<PageHeaderProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <div class="flex items-center h-20 p-5 bg-neutral-dark">
            <a href="/">
                <img src={Logo} class="flex-none" />
            </a>
            <div class="flex-1" />
            {/* TODO: Disabled for now, these pages are not ready yet
            <Button
                onClick={props.onJobsClicked}
                title={t('header.jobs')}
                variant="tertiary-grey"
                hidden={true}
            />
            <Button
                onClick={props.onAboutClicked}
                title={t('header.about')}
                variant="tertiary-grey"
            />
            */}
            <Button
                onClick={props.onCreateRoomClicked}
                title={t('header.createRoom')}
                variant="tertiary-grey"
            />
            {/* TODO: Enable when we figure out what it should do
            <Button
                onClick={props.onJoinRoomClicked}
                title={t('header.joinRoom')}
                variant="tertiary-grey"
            />
            */}
            <div class="flex-1" />
            {/* TODO: Enable when doing login 
            <Show when={props.isLoggedIn()}>
                <Button
                    onClick={props.onProfileClicked}
                    title={props.userName()}
                    variant="tertiary"
                />
            </Show>
            <Show when={!props.isLoggedIn()}>
                <Button
                    onClick={props.onLoginClicked}
                    title={t('header.loginRegister')}
                    variant="tertiary"
                />
            </Show>
            */}
        </div>
    );
};

export default PageHeader;
