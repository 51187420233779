import avatar1 from '@assets/icons/avatars/avatar_1.svg';
import avatar2 from '@assets/icons/avatars/avatar_2.svg';
import avatar3 from '@assets/icons/avatars/avatar_3.svg';
import avatar4 from '@assets/icons/avatars/avatar_4.svg';

class AvatarProvider {
    public allAvatars = [avatar1, avatar2, avatar3, avatar4];

    public getRandomAvatar(): string {
        return this.allAvatars[
            Math.floor(Math.random() * this.allAvatars.length)
        ];
    }
}

export default AvatarProvider;
