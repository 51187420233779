import { Accessor, Setter, Show, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import Player from '@model/Player';
import VotingHeader from '@molecules/VotingHeader';
import PlayersTable from '@organisms/PlayersTable';
import Text from '@atoms/Text';
import VotingButtons from '@molecules/VotingButtons';
import Button from '@atoms/Button';
import VotingModeratorFooter from '@molecules/VotingModeratorFooter';
import { PlayerVote } from '@model/PlayerVote';
import RoomLinkBox from '@molecules/RoomLinkBox';

export interface VotingSectionProps {
    sessionNumber: number;
    ticketName: string | null;
    players: Player[];
    isModerator: boolean;
    selectedScore: Accessor<PlayerVote>;
    setSelectedScore: (vote: PlayerVote) => void;
    onRevealVotes: () => void;
    onShowVotingHistory: () => void;
    onEndSession: (sessionName: string) => void;
    pokerAddress: string;
}

const VotingSection: Component<VotingSectionProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <VotingHeader
                sessionNumber={props.sessionNumber}
                ticketName={props.ticketName}
            />
            <div class="h-6" />
            <PlayersTable
                players={props.players}
                votesVisible={false}
                type="Voting"
            />
            <div class="h-6" />
            <Text color="light" fontWeight="semibold">
                {t('voting.voteButtonsLabel')}
            </Text>
            <div class="h-6" />
            <VotingButtons
                selectedScore={props.selectedScore}
                setSelectedScore={props.setSelectedScore}
            />
            <div class="h-3" />
            <Button
                variant="primary"
                fullWidth={true}
                onClick={props.onRevealVotes}
            >
                {t('voting.revealButton')}
            </Button>
            <Show when={props.isModerator}>
                <div>
                    <div class="h-6" />
                    <VotingModeratorFooter
                        onEndSessionPressed={props.onEndSession}
                        onShowHistoryPressed={props.onShowVotingHistory}
                    />
                </div>
            </Show>
            <div class="h-6" />
            <RoomLinkBox joinUrl={() => props.pokerAddress} />
        </>
    );
};

export default VotingSection;
