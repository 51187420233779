import { Accessor, Setter, Show, type Component } from 'solid-js';
import { useTranslationState } from '@i18n/TranslationProvider';
import Player from '@model/Player';
import VotingHeader from '@molecules/VotingHeader';
import PlayersTable from '@organisms/PlayersTable';
import Button from '@atoms/Button';
import VotingModeratorFooter from '@molecules/VotingModeratorFooter';
import ResultsTable from '@organisms/ResultsTable';
import TicketDescriptionBox from '@molecules/TicketDescriptionBox';
import RoomLinkBox from '@molecules/RoomLinkBox';

export interface VotingResultSectionProps {
    sessionNumber: number;
    ticketName: string | null;
    players: Player[];
    isModerator: boolean;
    votingTime: string;
    averageVote: number;
    isReady: Accessor<boolean>;
    setIsReady: (isReady: boolean) => void;
    nameSessionTickets: Accessor<boolean>;
    setNameSessionTickets: Setter<boolean>;
    ticketDescription: Accessor<string>;
    setTicketDescription: Setter<string>;
    onVoteAgain: () => void;
    onStartNewEstimation: () => void;
    onEndSession: (sessionName: string) => void;
    onShowVotingHistory: () => void;
    pokerAddress: string;
}

const VotingResultSection: Component<VotingResultSectionProps> = (props) => {
    const t = useTranslationState().t;
    return (
        <>
            <VotingHeader
                sessionNumber={props.sessionNumber}
                ticketName={props.ticketName}
            />
            <div class="h-6" />
            <PlayersTable
                players={props.players}
                votesVisible={true}
                type="Voting"
            />
            <div class="h-6" />
            <ResultsTable
                votingTime={props.votingTime}
                averageVote={props.averageVote}
            />
            <div class="h-3" />
            <Button
                variant="secondary"
                fullWidth={true}
                onClick={props.onVoteAgain}
            >
                {t('voting.voteAgain')}
            </Button>
            <div class="h-6" />
            <TicketDescriptionBox
                nameSessionTickets={props.nameSessionTickets}
                setNameSessionTickets={props.setNameSessionTickets}
                ticketDescription={props.ticketDescription}
                setTicketDescription={props.setTicketDescription}
            />
            <div class="h-3" />
            <Button
                variant="primary"
                fullWidth={true}
                onClick={props.onStartNewEstimation}
            >
                {t('voting.startNewEstimation')}
            </Button>
            <Show when={props.isModerator}>
                <div>
                    <div class="h-6" />
                    <VotingModeratorFooter
                        onEndSessionPressed={props.onEndSession}
                        onShowHistoryPressed={props.onShowVotingHistory}
                    />
                </div>
            </Show>
            <div class="h-6" />
            <RoomLinkBox joinUrl={() => props.pokerAddress} />
        </>
    );
};

export default VotingResultSection;
