import { Component, JSX } from 'solid-js';

export interface SeparatorProps {
    children: JSX.Element;
}

const Separator: Component<SeparatorProps> = (props) => {
    return (
        <div class="flex flex-row items-center">
            <div class="flex-1 bg-secondary h-px" />
            <p class="flex-none text-secondary font-SpaceGrotesk text-xs font-medium pr-5 pl-5">
                {props.children}
            </p>
            <div class="flex-1 bg-secondary h-px" />
        </div>
    );
};

export default Separator;
